const closeAlert = document.querySelector('#close-alert')
const closeNotice = document.querySelector('#close-notice')
const nameToggle = document.querySelector('#name-toggle');
const signOut = document.querySelector('#sign-out');
const adminDropdown = document.querySelector('#admin-dropdown');
const menubarToggle = document.querySelector('#menu-toggle');
const mobileAdminDropdown = document.querySelector('#mobile-admin-dropdown');
const bundlesDropdown = document.querySelector('#bundles-dropdown');
const learningCenterDropdown = document.querySelector('#learning-center-dropdown');
const ordersDropdown = document.querySelector('#orders-dropdown');
const productsDropdown = document.querySelector('#products-dropdown');
const reportsDropdown = document.querySelector('#reports-dropdown');
const eventsDropdown = document.querySelector('#events-dropdown');

nameToggle && nameToggle.addEventListener('click', (event) => {
    signOut.classList.toggle('hidden');
});

closeAlert && closeAlert.addEventListener('click', (event) => {
    const alert = document.querySelector('#alert');
    alert.remove();
});

closeNotice && closeNotice.addEventListener('click', (event) => {
    const notice = document.querySelector('#notice');
    notice.remove();
});

adminDropdown && adminDropdown.addEventListener('click', (event) => {
    const adminMenu = document.querySelector('#admin-dropdown-menu');
    adminMenu.classList.toggle('hidden');
});

bundlesDropdown && bundlesDropdown.addEventListener('click', (event) => {
    const bundlesMenu = document.querySelector('#bundles-dropdown-menu');
    bundlesMenu.classList.toggle('hidden');
});

learningCenterDropdown && learningCenterDropdown.addEventListener('click', (event) => {
    const learningCenterMenu = document.querySelector('#learning-center-dropdown-menu');
    learningCenterMenu.classList.toggle('hidden');
});

ordersDropdown && ordersDropdown.addEventListener('click', (event) => {
    const ordersMenu = document.querySelector('#orders-dropdown-menu');
    ordersMenu.classList.toggle('hidden');
});

productsDropdown && productsDropdown.addEventListener('click', (event) => {
    const productsMenu = document.querySelector('#products-dropdown-menu');
    productsMenu.classList.toggle('hidden');
});

reportsDropdown && reportsDropdown.addEventListener('click', (event) => {
    const reportsMenu = document.querySelector('#reports-dropdown-menu');
    reportsMenu.classList.toggle('hidden');
});

menubarToggle && menubarToggle.addEventListener('click', (e) => {
    document.querySelector('#menu-list').classList.toggle('hidden');
})

mobileAdminDropdown && mobileAdminDropdown.addEventListener('click', (e) => {
    document.querySelector('#mobile-admin-dropdown-menu').classList.toggle('hidden');
});

eventsDropdown && eventsDropdown.addEventListener('click', (e) => {
    document.querySelector('#events-dropdown-menu').classList.toggle('hidden');
});